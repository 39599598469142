<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <div class="text-center">
            <v-dialog
                    v-model="dialog"
                    persistent
                    width="50"
            >
                <img src='../../assets/loader_spinner.gif' style="width: 50px; ">

            </v-dialog>
        </div>
        <v-row>
            <v-col>
                <v-container class="my-5 adminContainer">
                    <v-row>
                        <v-col cols="0" md="2">
                            <admin-room-menu></admin-room-menu>
                        </v-col>
                        <v-col cols="12" md="10">
                            <v-row>
                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    할인 설정
                                    <v-btn
                                            color="error"
                                            class="mx-2 white&#45;&#45;text center-block float-right"
                                            x-small
                                            @click="btnClick(1)"
                                    >
                                        추가
                                    </v-btn>
                                </v-col>
                                <!--<v-col cols="12">
                                    <table class="table-top-s table-top-s-b">
                                        <colgroup>
                                            &lt;!&ndash;<col style="width:16%"/>&ndash;&gt;
                                            <col style="width:5%"/>
                                            <col style="width:12%"/>
                                            <col style="width:11%"/>
                                            <col style="width:11%"/>
                                            <col style="width:11%"/>
                                            <col style="width:8%"/>
                                            <col style="width:30%"/>
                                            <col style="width:10%"/>
                                            <col style="width:5%"/>
                                        </colgroup>
                                        <tr>
                                            &lt;!&ndash;<th>번호</th>&ndash;&gt;
                                            <th>번호</th>
                                            <th>구분</th>
                                            <th>시작일</th>
                                            <th>종료일</th>
                                            <th>할인가격</th>
                                            <th>단위</th>
                                            <th>적용요일</th>
                                            <th>적용</th>
                                            <th>삭제</th>
                                        </tr>
                                        <tr v-for="(item, i) in saleList"
                                            :key=i
                                        >
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td>
                                                <select v-model="item.saleRoomGubun" style="width: 100%">
                                                    <option value="0">객실</option>
                                                    <option value="1">캠핑장</option>
                                                </select>
                                            </td>
                                            <td>
                                                <input v-model="item.saleStartDate" placeholder="mm-dd">
                                            </td>
                                            <td>
                                                <input v-model="item.saleEndDate" placeholder="mm-dd">
                                            </td>
                                            <td>
                                                <input v-model="item.salePrice" style="text-align: right;">
                                            </td>
                                            <td>
                                                <select v-model="item.saleType" style="width: 100%">
                                                    <option value="0">원</option>
                                                    <option value="1">%</option>
                                                </select>
                                            </td>
                                            <td>
                                                <input type="checkbox" id="checkbox1" v-model="item.sunYn"
                                                       class="checkbox-custom-admin-m0">
                                                <label class="label-custom-admin">일</label>
                                                <input type="checkbox" id="checkbox2" v-model="item.monYn"
                                                       class="checkbox-custom-admin-m0">
                                                <label class="label-custom-admin">월</label>
                                                <input type="checkbox" id="checkbox3" v-model="item.tueYn"
                                                       class="checkbox-custom-admin-m0">
                                                <label class="label-custom-admin">화</label>
                                                <input type="checkbox" id="checkbox4" v-model="item.wedYn"
                                                       class="checkbox-custom-admin-m0">
                                                <label class="label-custom-admin">수</label>
                                                <input type="checkbox" id="checkbox5" v-model="item.thuYn"
                                                       class="checkbox-custom-admin-m0">
                                                <label class="label-custom-admin">목</label>
                                                <input type="checkbox" id="checkbox6" v-model="item.friYn"
                                                       class="checkbox-custom-admin-m0">
                                                <label class="label-custom-admin">금</label>
                                                <input type="checkbox" id="checkbox7" v-model="item.satYn"
                                                       class="checkbox-custom-admin-m0">
                                                <label class="label-custom-admin">토</label>
                                            </td>
                                            <td>
                                                <select v-model="item.useYn" style="width: 100%">
                                                    <option value="Y">적용</option>
                                                    <option value="N">미적용</option>
                                                </select>
                                            </td>
                                            <td>
                                                <v-btn
                                                        color="error"
                                                        class="mx-4 white&#45;&#45;text center-block float-right"
                                                        x-small
                                                        @click="update(item, 1)"
                                                >
                                                    삭제
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </table>
                                    <v-col>

                                        <v-btn
                                                color="primary"
                                                class="mx-4 white&#45;&#45;text center-block"
                                                @click="btnClick(2)"
                                        >
                                            저장
                                        </v-btn>
                                    </v-col>
                                </v-col>-->

                                <v-col cols="12" class="overflow-x-auto">
                                    <table class="table-top-s table-top-s-b" style="min-width: 980px;">
                                        <colgroup>
                                            <!--<col style="width:16%"/>-->
                                            <!--<col style="width:5%"/>-->
                                            <col style="width:10%"/>
                                            <col style="width:18%"/>
                                            <col style="width:18%"/>
                                            <col style="width:8%"/>
                                            <col style="width:7%"/>
                                            <col style="width:25%"/>
                                            <col style="width:10%"/>
                                            <col style="width:4%"/>
                                        </colgroup>
                                        <tr>
                                            <!--<th>번호</th>-->
                                            <!--<th>번호</th>-->
                                            <th>구분</th>
                                            <th>시작일</th>
                                            <th>종료일</th>
                                            <th>할인가격</th>
                                            <th>단위</th>
                                            <th>적용요일</th>
                                            <th>적용</th>
                                            <th>삭제</th>
                                        </tr>
                                        <tr v-for="(item, i) in saleList"
                                            :key=i
                                        >
                                            <!--<td>
                                                {{i+1}}
                                            </td>-->
                                            <td>
                                                <select v-model="item.saleRoomGubun" style="width: 100%">
                                                    <option value="0">객실</option>
                                                    <option value="1">캠핑장</option>
                                                </select>
                                            </td>
                                            <td>
                                                <div style="display: flex;">
                                                    <select v-model="item.startDateM" style="width: 45%"
                                                            @change="selectChange($event, item)">
                                                        <option v-for="(n, i) in 12" :value="n">{{n}}월</option>
                                                    </select>
                                                    <span class="mx-1">-</span>
                                                    <select v-model="item.startDateD" style="width: 45%"
                                                            @change="selectChange($event, item)">
                                                        <option v-for="(n, i) in 31" :value="n">{{n}}일</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <div style="display: flex;">
                                                    <select v-model="item.endDateM" style="width: 45%"
                                                            @change="selectChange($event, item)">
                                                        <option v-for="(n, i) in 12" :value="n">{{n}}월</option>
                                                    </select>
                                                    <span class="mx-1">-</span>
                                                    <select v-model="item.endDateD" style="width: 45%"
                                                            @change="selectChange($event, item)">
                                                        <option v-for="(n, i) in 31" :value="n">{{n}}일</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <input v-model="item.salePrice" style="text-align: right;">
                                            </td>
                                            <td>
                                                <select v-model="item.saleType" style="width: 100%">
                                                    <option value="0">원</option>
                                                    <option value="1">%</option>
                                                </select>
                                            </td>
                                            <td>
                                                <input type="checkbox" id="checkbox1" v-model="item.sunYn"
                                                       class="checkbox-custom-admin-m0">
                                                <label class="label-custom-admin">일</label>
                                                <input type="checkbox" id="checkbox2" v-model="item.monYn"
                                                       class="checkbox-custom-admin-m0">
                                                <label class="label-custom-admin">월</label>
                                                <input type="checkbox" id="checkbox3" v-model="item.tueYn"
                                                       class="checkbox-custom-admin-m0">
                                                <label class="label-custom-admin">화</label>
                                                <input type="checkbox" id="checkbox4" v-model="item.wedYn"
                                                       class="checkbox-custom-admin-m0">
                                                <label class="label-custom-admin">수</label>
                                                <input type="checkbox" id="checkbox5" v-model="item.thuYn"
                                                       class="checkbox-custom-admin-m0">
                                                <label class="label-custom-admin">목</label>
                                                <input type="checkbox" id="checkbox6" v-model="item.friYn"
                                                       class="checkbox-custom-admin-m0">
                                                <label class="label-custom-admin">금</label>
                                                <input type="checkbox" id="checkbox7" v-model="item.satYn"
                                                       class="checkbox-custom-admin-m0">
                                                <label class="label-custom-admin">토</label>
                                            </td>
                                            <td>
                                                <select v-model="item.useYn" style="width: 100%">
                                                    <option value="Y">적용</option>
                                                    <option value="N">미적용</option>
                                                </select>
                                            </td>
                                            <td>
                                                <v-btn
                                                        color="error"
                                                        class="mx-1 white--text center-block float-right"
                                                        x-small
                                                        @click="update(item, 1)"
                                                >
                                                    삭제
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </table>
                                    <v-col>

                                        <v-btn
                                                color="primary"
                                                class="mx-4 white--text center-block"
                                                @click="btnClick(2)"
                                        >
                                            저장
                                        </v-btn>
                                    </v-col>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                </v-container>
            </v-col>
        </v-row>

    </div>
</template>

<script>

    import AdminRoomMenu from '../../components/AdminRoomMenu'

    export default {
        name: 'AdminSaleList',
        components: {
            AdminRoomMenu
        },
        data: () => ({
            page: 1,
            pageCount: 0,
            itemsPerPage: 30,

            saleList: [],
            stopList: [],
            tmpSaleId: 0,
            dialog: false,

        }),
        created: function () {
            // 맨위로
            if ( this.$isMobile()) {
                window.scrollTo({top: 400, left: 0, behavior: 'smooth'});
            } else {
                window.scrollTo(0, 0);
            }
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));
            this.getList({});
        },
        methods: {
            getList(val) {
                return this.$store.dispatch("admin/getSaleList", {})
                    .then((resp) => {
                        setTimeout(() => {
                            this.saleList = resp.message;

                            this.trueCheck();

                            this.setSaleInfo();

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            trueCheck() {

                let _this = this;
                _this.saleList.forEach(function (e) {
                    e.sunYn = JSON.parse(e.sunYn);
                    e.monYn = JSON.parse(e.monYn);
                    e.tueYn = JSON.parse(e.tueYn);
                    e.wedYn = JSON.parse(e.wedYn);
                    e.thuYn = JSON.parse(e.thuYn);
                    e.friYn = JSON.parse(e.friYn);
                    e.satYn = JSON.parse(e.satYn);
                });


            },
            btnClick(val) {

                let _this = this;

                switch (val) {
                    case 0 : {   // 주말 저장


                        break;
                    }
                    case 1 : {   // 할인 추가

                        let formdata = {
                            saleRoomGubun: 0,
                            saleType: 0,
                            salePrice: "0",
                            saleStartDate: "",
                            saleEndDate: "",
                            sunYn: false,
                            monYn: false,
                            tueYn: false,
                            wedYn: false,
                            thuYn: false,
                            friYn: false,
                            satYn: false,
                            useYn: 'Y',
                            lstModPrs: "admin",
                            tmpSaleId: this.tmpSaleId,
                        };

                        this.saleList.push(formdata);

                        this.tmpSaleId += this.tmpSaleId + 1;

                        break;
                    }
                    case 2 : {   // 할인정보 저장
                        if (!confirm("할인정보 설정을 저장 하시겠습니까?")) {
                            // 취소(아니오) 버튼 클릭 시 이벤트
                        } else {
                            // 확인(예) 버튼 클릭 시 이벤트

                            return this.$store.dispatch("admin/deleteSales", {isDel: 'true'})
                                .then((resp) => {
                                    setTimeout(() => {
                                        let index = 0;
                                        let listIndex = _this.saleList.length;

                                        _this.saleList.forEach(function (e) {

                                            _this.$store.dispatch("admin/insertSale", e)
                                                .then((resp) => {

                                                    index += 1;

                                                    if (index === listIndex) {
                                                        setTimeout(() => {
                                                            _this.getList({});
                                                            alert("저장했습니다.");
                                                            _this.dialog = false;

                                                        }, 300)
                                                    }
                                                })
                                                .catch((err) => {

                                                });
                                        });

                                    }, 300)
                                })
                                .catch((err) => {
                                })


                           /* let index = 0;
                            let listIndex = _this.saleList.length;

                            _this.dialog = true;

                            _this.saleList.forEach(function (e) {
                                _this.$store.dispatch("admin/updateSale", e)
                                    .then((resp) => {

                                        index += 1;

                                        if (index === listIndex) {

                                            setTimeout(() => {
                                                //location.href = "/admin/default?comnGubun=" + this.comnInfo.comnGubun
                                                _this.getList({});
                                                alert("저장했습니다.");
                                                _this.dialog = false;

                                            }, 300)

                                        }


                                    })
                                    .catch((err) => {

                                    });
                            });*/
                        }
                        break;
                    }
                    case 3 : {   // 할인정보 삭제


                        break;
                    }
                }

            },
            update(val, isUpdate) {
                switch (isUpdate) {
                    case 1 : {
                        if (!confirm("할인설정 목록을 삭제 하시겠습니까?")) {
                            // 취소(아니오) 버튼 클릭 시 이벤트
                        } else {
                            // 확인(예) 버튼 클릭 시 이벤트
                            if (val.tmpSaleId != undefined) {

                                for (let i = 0; this.saleList.length > i; i++) {

                                    if (this.saleList[i].tmpSaleId === val.tmpSaleId) {
                                        this.saleList.splice(i, 1);
                                    }
                                }
                            } else {
                                for (let i = 0; this.saleList.length > i; i++) {

                                    if (this.saleList[i].saleId === val.saleId) {
                                        this.saleList.splice(i, 1);
                                    }
                                }
                            }
                        }
                        break;
                    }

                }

            },
            setSaleInfo() {

                for (let i = 0; this.saleList.length > i; i++) {

                    this.saleList[i].startDateM = Number(this.saleList[i].saleStartDate.split("-")[0]);
                    this.saleList[i].startDateD = Number(this.saleList[i].saleStartDate.split("-")[1]);

                    this.saleList[i].endDateM = Number(this.saleList[i].saleEndDate.split("-")[0]);
                    this.saleList[i].endDateD = Number(this.saleList[i].saleEndDate.split("-")[1]);

                }

            },
            selectChange(event, item) {

                item.saleStartDate = this.numberPad(item.startDateM, 2) + "-" + this.numberPad(item.startDateD, 2);
                item.saleEndDate = this.numberPad(item.endDateM, 2) + "-" + this.numberPad(item.endDateD, 2);

            },
            numberPad(n, width) {
                n = n + '';
                return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
            },

        },
        mounted() {
            this.$nextTick(() => {

            })
        },
        computed: {
        },

    }
</script>

<style>

</style>


